// PopChat.js
import React, {
  useRef,
  useState,
  useEffect,
} from 'react';

import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

import Tooltip from '@mui/material/Tooltip';
import CloseIcon from '@material-ui/icons/Close';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

import questions from './data/questions.json';
import { sendQueryToServer, sendFeedback } from './service/api';

// components
import Pills from '../Pills';
import Footer from './components/Footer';
import Header from './components/Header';
import TextInput from './components/TextInput';
import MessageArea from './components/MessageArea';

import FeedbackDialog from './components/FeedbackDialog';

import {
  ChatIcon,
  StyledIcon,
  ChatContainer,
  IconContainer,
  ChatboxContainer,
  ScrollDownButton,
  MsgAreaContainer,
  FeedbackListHeader,
  FeedbackListWrapper,
  FeedbackListContainer,
  QuestionStarterContainer,
} from './styles';

import feedbacks from './data/feedback.json';

const languageModel = 'gpt-4';

export const PopChat = () => {
  const textRef = React.createRef();
  const messageContainerRef = useRef(null);
  const messagesEndRef = useRef(null);

  // content states
  const [messages, setMessages] = useState([]);
  const [streamMessage, setStreamMessage] = useState('');

  // view states
  const [loading, setLoading] = useState(false);
  const [chatopen, setChatopen] = useState(false);
  const [isFullView, setFullView] = useState(false);
  const [showMessageIcon, setShowMessagIcon] = useState(true);
  const [isScrollAvailable, setIsScrollAvailable] = useState(false);

  // feedback states
  const [showFeedbackList, toggleFeedbackList] = useState(false);
  const [showFeedbackDialog, toggleFeedbackDialog] = useState(false);

  const [tooltipText, setTooltipText] = useState('Copy response');
  const [timerId, setTimerId] = useState(null);
  const [threadId, setThreadId] = useState(null);

  // AbortController
  const [controller, setController] = useState(null);

  const toggle = () => {
    setChatopen(!chatopen);
  };

  const checkScrollAvailability = () => {
    const container = messageContainerRef.current;
    if (container) {
      const hasMoreScroll = container.scrollHeight > container.clientHeight;
      const isScrolledToBottom = container
        .scrollHeight - container.scrollTop - 20 <= container.clientHeight;

      setIsScrollAvailable(hasMoreScroll && !isScrolledToBottom);
    }
  };

  const scrollToBottom = () => {
    const container = messageContainerRef.current;
    if (container) {
      container.scrollTo({
        top: container.scrollHeight,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    if (threadId === null) {
      setThreadId(uuidv4());
    }
  }, [messages, threadId]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const container = messageContainerRef.current;

    // Check scroll availability initially
    checkScrollAvailability();

    if (container) {
      // Add scroll event listener
      container.addEventListener('scroll', checkScrollAvailability);

      // Cleanup on unmount
      return () => {
        container.removeEventListener('scroll', checkScrollAvailability);
      };
    }
  }, [messages]);

  useEffect(() => {
    if (loading) {
      scrollToBottom();
    }
  }, [loading]);

  const handleSend = async (text) => {
    if (loading || text === '') return;

    if (showFeedbackList) toggleFeedbackList(false);

    const query = text || textRef.current.value;

    if (query !== '') {
      const userMessage = {
        text: query,
        sender: 'user',
        type: 'text',
        timeStamp: moment(new Date()).format('hh:mm a'),
        id: uuidv4(),
      };

      // append initial bot message to display stream message
      const botMessage = {
        text: '',
        sender: 'bot',
        type: 'markdown',
        id: uuidv4(),
      };

      // add user message
      setMessages([...messages, userMessage, botMessage]);

      setLoading(true);

      // clear chat message
      textRef.current.value = '';

      setStreamMessage('');

      const onStreamCallBack = ({ chunkMessage, messageId }) => {
        setLoading(false);
        const botActualMessage = {
          ...botMessage,
          text: chunkMessage,
          sender: 'bot',
          type: 'markdown',
          timeStamp: moment(new Date()).format('hh:mm a'),
          messageId,
        };
        setMessages([...messages, userMessage, botActualMessage]);
        checkScrollAvailability();
      };

      sendQueryToServer(query, threadId, languageModel, onStreamCallBack, (newData) => {
        setStreamMessage((prevData) => prevData + newData);
        checkScrollAvailability();
      }, setController);
    }
  };

  const handleCopyClick = (text) => {
    navigator.clipboard.writeText(text);

    // Update tooltip text
    setTooltipText('Copied!');

    // Clear previous timer if it exists
    if (timerId) {
      clearTimeout(timerId);
    }

    // Revert text back after 3 seconds
    const newTimerId = setTimeout(() => {
      setTooltipText('Copy response');
    }, 1000);

    setTimerId(newTimerId);
  };

  useEffect(() => {
    if (showFeedbackList && messageContainerRef.current) {
      messageContainerRef.current.scrollTo({
        top: messageContainerRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }
  }, [showFeedbackList]);

  const onDisplayFeedbackList = (shouldDisplay, message) => {
    const lastMessage = messages[messages.length - 1];
    // display only feedback only for the last message
    if (lastMessage.id === message.id) {
      toggleFeedbackList(shouldDisplay);
    }
  };

  const renderPill = ({ text }) => (
    <div
      style={{
        cursor: loading ? 'default' : 'pointer',
        opacity: loading ? '50%' : '100%',
        marginTop: 12,
      }}
      onClick={() => handleSend(text)}
    >
      <Pills message={text} bgColor='darkseagreen' color='white' />
    </div>
  );

  const renderFeedbackPill = ({ text, openDialog }) => (
    <div
      style={{
        cursor: 'pointer',
        marginTop: 18,
        marginRight: 8,
      }}
      onClick={() => {
        if (openDialog) {
          toggleFeedbackDialog(true);
        }
        // display thank you feedback
        toggleFeedbackList(false);
        // @TODO send to backend
      }}
    >
      <Pills message={text} bgColor='mintcream' color='black' />
    </div>
  );

  const onAbortClick = () => {
    if (controller) {
      controller.abort();
    }
  };

  const onHandleRefesh = () => {
    if (!loading) {
      setMessages([]);
      setThreadId(uuidv4());
    }
  };

  const onHandleFullView = () => {
    setFullView(!isFullView);
  };

  const onHandleCloseChat = () => {
    toggle();
    setShowMessagIcon(true);
    setFullView(false);
  };

  const onHandleUpdateFeedback = ({ message, rating }) => {
    // find index to update
    const index = messages.findIndex((m) => m.id === message.id);

    if (index !== -1) {
      messages[index] = { ...messages[index], rating };
      // @TODO: call endpoint
      sendFeedback({
        rating,
        comment: messages[index].text || '',
        conversationId: messages[index].id,
        messageId: messages[index].messageId,
      });
    }
    setMessages(messages);
  };

  const onCancelFeeback = () => {
    toggleFeedbackDialog(false);
  };

  const onSendFeedback = () => {
    toggleFeedbackDialog(false);
  };

  return (
    <ChatContainer isFullView={isFullView} isOpen={chatopen}>
      <ChatboxContainer isFullView={isFullView} isOpen={chatopen}>
        <Header
          loading={loading}
          isFullView={isFullView}
          onHandleRefesh={onHandleRefesh}
          onHandleFullView={onHandleFullView}
          onHandleCloseChat={onHandleCloseChat}
        />

        <MsgAreaContainer
          isFullView={isFullView}
          ref={messageContainerRef}
        >
          <div style={{ background: 'white', padding: 12, borderRadius: 12 }}>
            <span>
              Hi there! If you have any questions, I&apos;m here to assist you.
            </span>
          </div>

          <div
            style={{
              background: 'white',
              padding: 12,
              borderRadius: 12,
              marginTop: 12,
            }}
          >
            <span>
              Here are a few helpful things you can check out to get started:
            </span>
          </div>

          <QuestionStarterContainer>
            {questions.messages.map((q) => (
              renderPill({ text: q })
            ))}
          </QuestionStarterContainer>

          <MessageArea
            messages={messages}
            loading={loading}
            streamMessage={streamMessage}
            handleCopyClick={handleCopyClick}
            handleUpdateFeedback={onHandleUpdateFeedback}
            onDisplayFeedbackList={onDisplayFeedbackList}
            tooltipText={tooltipText}
          />

          {(showFeedbackList && messages && messages.length) ? (
            <FeedbackListContainer>
              <FeedbackListHeader>
                <span style={{ flex: 1, marginTop: 4 }}>Tell us more:</span>
                <StyledIcon>
                  <CloseIcon
                    style={{
                      color: 'black',
                      fontSize: 16,
                    }}
                    alt="close-icon"
                    onClick={() => toggleFeedbackList(false)}
                  />
                </StyledIcon>
              </FeedbackListHeader>
              <FeedbackListWrapper>
                {feedbacks.messages.slice(0, 5).map((f) => (
                  renderFeedbackPill({ text: f })
                ))}
                {renderFeedbackPill({ text: 'More...', openDialog: true })}
              </FeedbackListWrapper>
            </FeedbackListContainer>
          ) : null}

          <div ref={messagesEndRef} />

          {isScrollAvailable && (
            <ScrollDownButton onClick={scrollToBottom} isFullView={isFullView} >
              <ArrowDownwardIcon
                style={{ color: 'white', fontSize: 24 }}
                alt="chat-icon"
              />
            </ScrollDownButton>
          )}
        </MsgAreaContainer>

        <TextInput
          textRef={textRef}
          loading={loading}
          handleSend={handleSend}
          onAbortClick={onAbortClick}
        />
        <Footer />
      </ChatboxContainer>

      {
        showMessageIcon && (
          <Tooltip placement="bottom-end" title={'Chat with me!'}>
            <IconContainer
              onClick={() => {
                setShowMessagIcon(false);
                setChatopen(true);
              }}
              data-testid="chatbot-button"
            >
              <ChatIcon>
                <QuestionAnswerIcon
                  style={{ color: 'white', fontSize: 32 }}
                  alt="chat-icon"
                />
              </ChatIcon>
            </IconContainer>
          </Tooltip>
        )
      }

      <FeedbackDialog
        open={showFeedbackDialog}
        onCancel={onCancelFeeback}
        onConfirm={onSendFeedback}
      />
    </ChatContainer >
  );
};

export default PopChat;
