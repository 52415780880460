import styled from 'styled-components';
/* eslint-disable no-nested-ternary, operator-linebreak */

export const ChatIcon = styled.div`
  height: 64px;
  width: 64px;
  background: #1d5aa6;
  border-radius: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const IconContainer = styled.div`
  width: 100%;
  height: 25%;
  cursor: pointer;
  justify-content: flex-end;
  display: flex;
`;

export const ChatContainer = styled.div`
  position: fixed;

  // full view
  bottom: ${(props) => (props.isOpen ? 0 : '24px')};
  right: ${(props) => (props.isOpen ? 0 : '24px')};
  width: ${(props) => (props.isFullView ? '100%' : '440px')};

  // full view only
  top: ${(props) => (props.isOpen && 0)};
  height: ${(props) => (props.isOpen && '100%')};
  display: ${(props) => (props.isOpen && 'flex')};
  justify-content: ${(props) => (props.isOpen && 'center')};
  z-index: ${(props) => (props.isOpen && 999)};
  div table {
    margin-bottom: 10px;
  }
`;

export const ChatboxContainer = styled.div`
  width: 100%;
  background-color: #eee;
  z-index: 999;
  border: 0.5px solid gray;

  // check if open
  display: ${(props) => (props.isOpen ? 'flex' : 'none')};

  // full view mode
  height: 100%;
  margin-bottom: 0px;

  // full view only
  flex-direction: column;
  flex: 1;
`;

export const MsgAreaContainer = styled.div`
  overflow: scroll;
  flex: 1;

  // full view mode
  padding: ${(props) => (props.isFullView ? '24px 40px 40px 40px' : '16px')};
`;

export const StyledIcon = styled.label`
  transition: 'background-color 0.3s ease';
  background-color: none;
  cursor: pointer;
  margin-right: 5px;
  padding: 2px 5px;

  &:hover, &:focus {
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.05);
    transform: scale(1.2);
  }
`;

export const QuestionStarterContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  margin-top: 8px;
`;

export const FeedbackListContainer = styled.div`
  padding: 12px;
  border: 1px;
  border-color: gray;
  border-style: solid;
  margin-top: 16px;
  border-radius: 4px;
`;

export const FeedbackListHeader = styled.div`
  display: flex;
  flex-direction: row;
`;

export const FeedbackListWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 8px;
`;

export const ScrollDownButton = styled.button`
  position: fixed;
  bottom: 132px;
  right: ${(props) => (props.isFullView ? '50%' : '200px')};
  background-color: #000000;
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  opacity: 0.5;
`;
