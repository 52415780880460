import React from 'react';
import PropTypes from 'prop-types';

// styled components
import { StyledSpan } from './styles';

const Pills = (props) => {
  const {
    message,
    color = 'black',
    bgColor = 'white',
    fontSize = 12,
    paddingTop = 8,
    paddingBottom = 8,
    paddingRight = 12,
    paddingLeft = 12,
  } = props;

  return <StyledSpan
    style={{
      color,
      backgroundColor: bgColor,
      fontSize,
      paddingTop,
      paddingBottom,
      paddingRight,
      paddingLeft,
    }}>
    {message}
  </StyledSpan>;
};

Pills.propTypes = {
  color: PropTypes.string,
  bgColor: PropTypes.string,
  fontSize: PropTypes.number,
  paddingTop: PropTypes.number,
  paddingBottom: PropTypes.number,
  paddingRight: PropTypes.number,
  paddingLeft: PropTypes.number,
  message: PropTypes.string.isRequired,
};
export default Pills;
