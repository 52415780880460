import React, { useState } from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import Button from '../../Buttons';
import Pills from '../../Pills';

import TextInput from '../../UserInputTypes/TextInput';

import feedbacks from '../data/feedback.json';

const ActionButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-right:15px;
`;

/**
 * Feedback dialog
 */
function FeedbackDialog({
  open,
  onCancel,
  onConfirm,
}) {
  const [feedback, setFeedBack] = useState('');
  const [selectedPill, setSelectedPill] = useState('');

  const resetDialogForm = () => {
    setFeedBack('');
    setSelectedPill('');
  };

  const onHandleCloseDialog = () => {
    onCancel();
    resetDialogForm();
  };

  const renderPill = ({ text }) => (
    <div
      style={{
        cursor: 'pointer',
        marginTop: 24,
        marginRight: 12,
      }}
      onClick={() => {
        setSelectedPill(text);
        setFeedBack('');
        // display thank you feedback
        // toggleFeedbackList(false);
        // @TODO send to backend
      }}
    >
      <Pills
        message={text}
        bgColor={`${selectedPill === text ? 'cadetblue' : 'mintcream'}`}
        color={`${selectedPill === text ? 'white' : 'black'}`}
      />
    </div>
  );

  const content = (
    <div style={{ width: '100%' }}>
      <div style={{ display: 'flex', flexWrap: 'wrap', marginBottom: 24 }}>
        {feedbacks.messages.map((f) => (
          renderPill({ text: f })
        ))}
        {renderPill({ text: 'Other...' })}
      </div>
      <div style={{ paddingLeft: 12, paddingRight: 12 }}>
        <TextInput
          value={feedback}
          placeholder={'(Optional) Feel free to add specific details'}
          handleInputChange={(inputValue) => {
            if (inputValue !== '') {
              setSelectedPill('');
            }
            setFeedBack(inputValue);
          }}
          fullWidth
        />
      </div>
    </div>
  );

  return (
    <Dialog
      open={open}
      onClose={onHandleCloseDialog}
      scroll="paper"
      fullWidth={true}
      maxWidth="md"
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
    >
      <DialogTitle>Provide additional feedback</DialogTitle>

      <IconButton
        aria-label="close"
        onClick={onHandleCloseDialog}
        sx={(theme) => ({
          position: 'absolute',
          right: 8,
          top: 8,
          color: theme.palette.grey[500],
        })}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers={true}>
        {content}
      </DialogContent>
      <DialogActions>
        <ActionButtonsWrapper>
          <Button
            name="SubmitFeedback"
            title="Submit"
            variant="contained"
            role="btn-submit"
            onClick={() => {
              onConfirm(feedback);
              resetDialogForm();
            }}
            disabled={feedback === '' && selectedPill === ''}
            data-testid="btn-submit"
          />
        </ActionButtonsWrapper>
      </DialogActions>
    </Dialog>
  );
}

FeedbackDialog.propTypes = {
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  open: PropTypes.bool,
};

export default FeedbackDialog;
