import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Tooltip from '@mui/material/Tooltip';

import CloseIcon from '@material-ui/icons/Close';
import ThumbUpIcon from '@material-ui/icons/ThumbUpAltOutlined';
import ThumbUpIconPressed from '@material-ui/icons/ThumbUpAlt';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import ThumbDownIcon from '@material-ui/icons/ThumbDownOutlined';
import ThumbDownIconPressed from '@material-ui/icons/ThumbDownAlt';

import Pills from '../../Pills';
import CONSTANTS from '../constants';
import feedbacks from '../data/feedback.json';

import {
  StyledIcon,
  AnimateIcons,
  FeedbackListHeader,
  FeedbackListWrapper,
  FeedbackListContainer,
} from './styles';

const FeedbackSection = ({
  toAnimate,
  message,
  tooltipText,
  handleCopyClick,
  handleUpdateFeedback,
  updateFeedback,
  showFeedbackDialog,
  parentRef,
}) => {
  const ref = useRef(null);

  const [showStatisfiedIcon, toggleStatisfiedIcon] = useState(true);
  const [showUnstatisfiedIcon, toggleUnStatisfiedIcon] = useState(true);

  const [showFeedbackList, toggleFeedbackList] = useState(false);

  useEffect(() => {
    // scroll to bottom once the list are shown
    if (showFeedbackList) {
      const childRef = ref.current;
      if (parentRef.current && childRef) {
        // // Scroll the parent to the child's position
        parentRef.current.scrollTo({
          top: childRef.offsetTop - 100,
          behavior: 'smooth',
        });
      }
    }
  }, [showFeedbackList]);

  const renderFeedbackPill = ({ text, command }) => (
    <div
      style={{
        cursor: 'pointer',
        marginTop: 18,
        marginRight: 8,
      }}
      onClick={() => {
        if (command) {
          // process command request
          command();
        } else {
          updateFeedback({
            message,
            rating: CONSTANTS.FEEDBACK_RATINGS.THUMBS_DOWN,
            comment: text,
          });
        }
        toggleFeedbackList(false);
      }}
    >
      <Pills message={text} bgColor='mintcream' color='black' />
    </div>
  );

  return (
    <>
      <AnimateIcons toAnimate={toAnimate} ref={ref}>
        <Tooltip placement="bottom-start" title={tooltipText}>
          <StyledIcon>
            <FileCopyIcon
              data-testid="btn-copy-response"
              onClick={() => handleCopyClick(message.text)}
              style={{
                color: '#1d5aa6',
                fontSize: 16,
              }}
              alt="good-response-icon"
            />
          </StyledIcon>
        </Tooltip>
        {showStatisfiedIcon && (
          <>
            {showUnstatisfiedIcon ? (
              <StyledIcon>
                <ThumbUpIcon
                  style={{
                    color: '#1d5aa6',
                    fontSize: 16,
                  }}
                  alt="good-response-icon"
                  onClick={() => {
                    handleUpdateFeedback({
                      message,
                      rating: CONSTANTS.FEEDBACK_RATINGS.THUMBS_UP,
                    });
                    toggleUnStatisfiedIcon(false);
                  }}
                />
              </StyledIcon>
            ) : (
              <ThumbUpIconPressed
                style={{
                  color: '#1d5aa6',
                  fontSize: 16,
                }}
                alt="good-response-icon"
              />
            )}
          </>
        )}
        {showUnstatisfiedIcon && (
          <>
            {showStatisfiedIcon ? (
              <StyledIcon>
                <ThumbDownIcon
                  style={{
                    color: '#1d5aa6',
                    fontSize: 16,
                  }}
                  alt="bad-response-icon"
                  onClick={() => {
                    toggleStatisfiedIcon(false);
                    toggleFeedbackList(true);
                  }}
                />
              </StyledIcon>
            ) : (
              <ThumbDownIconPressed
                style={{
                  color: '#1d5aa6',
                  fontSize: 16,
                }}
                alt="bad-response-icon"
              />
            )}
          </>
        )}
      </AnimateIcons>

      {showFeedbackList && (
        <FeedbackListContainer>
          <FeedbackListHeader>
            <span style={{ flex: 1, marginTop: 4 }}>Tell us more:</span>
            <StyledIcon>
              <CloseIcon
                style={{
                  color: 'black',
                  fontSize: 16,
                }}
                alt="close-icon"
                onClick={() => {
                  toggleFeedbackList(false);

                  // send feedback without comment
                  updateFeedback({
                    message,
                    rating: CONSTANTS.FEEDBACK_RATINGS.THUMBS_DOWN,
                  });
                }}
              />
            </StyledIcon>
          </FeedbackListHeader>
          <FeedbackListWrapper>
            {feedbacks.messages.slice(0, 5).map((f) => (
              renderFeedbackPill({ text: f })
            ))}
            {renderFeedbackPill({
              text: 'More...',
              command: () => {
                const updatedMessage = {
                  ...message,
                  rating: CONSTANTS.FEEDBACK_RATINGS.THUMBS_DOWN,
                };

                showFeedbackDialog({
                  displayDialog: true,
                  message: updatedMessage,
                });
              },
            })}
          </FeedbackListWrapper>

        </FeedbackListContainer>
      )}
    </>
  );
};

FeedbackSection.propTypes = {
  toAnimate: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  tooltipText: PropTypes.string.isRequired,
  handleCopyClick: PropTypes.func.isRequired,
  handleUpdateFeedback: PropTypes.func.isRequired,
  updateFeedback: PropTypes.func.isRequired,
  showFeedbackDialog: PropTypes.func.isRequired,
  parentRef: PropTypes.any,
};

export default FeedbackSection;
