// TODO need to make this url dynamic
const BASE_URL = 'https://assistant.partner.developer-dev.autodesk.com';

// Helper function to process the content from the server
const getContent = (chunk) => {
  const lines = chunk.split('\n');
  const dataLines = lines
    .filter((line) => line.startsWith('data:'))
    .map((line) => line.replace('data: ', '').trim());
  const contents = dataLines.map((line) => {
    try {
      const json = JSON.parse(line);
      return json.content;
    } catch (e) {
      console.log('JSON.parse ERROR', e);
      return line;
    }
  });
  return contents.join('');
};

export const sendQueryToServer = (
  query,
  threadId,
  languageModel,
  onStreamCallBack,
  onDataCallback,
  setController,
) => {
  const ctlr = new AbortController();
  setController(ctlr);
  const { signal } = ctlr;

  const url = `${BASE_URL}/v1/conversations`;

  const req = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'text/event-stream',
    },
    body: JSON.stringify({
      query,
      id: threadId,
      model: languageModel,
    }),
    signal,
  };

  fetch(url, req)
    .then((response) => {
      // Extract the `x-adsk-message-id` header
      const messageId = response.headers.get('x-adsk-message-id');

      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let chunkMessage = '';
      const read = () => {
        reader.read().then(({ done, value }) => {
          if (done) {
            onStreamCallBack({ chunkMessage, messageId });
            return;
          }
          const chunk = decoder.decode(value, { stream: true });
          const content = getContent(chunk);
          chunkMessage += content;
          onDataCallback(content);
          read();
        }).catch((error) => {
          if (error.name === 'AbortError') {
            console.log('Aborted');
            onStreamCallBack({ chunkMessage, messageId });
          } else {
            console.log('read error', error);
            onStreamCallBack({
              chunkMessage: `${chunkMessage} !!read error!! ${error}`,
              messageId,
            });
          }
        });
      };
      read();
    })
    .catch((error) => {
      console.log('sendQueryToServer: error', error);
      onStreamCallBack('');
    });
};

export const sendFeedback = async ({
  rating,
  comment,
  conversationId,
  messageId,
}) => {
  const url = `${BASE_URL}/v1/feedbacks`;

  const req = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      rating,
      comment,
      conversationId,
      messageId,
    }),
  };

  try {
    const response = await fetch(url, req);

    if (!response.ok) {
      throw new Error('Failed to submit feedback');
    }

    await response.json();
    // setFeedback(data);
  } catch (error) {
    // setError(error.message);
  } finally {
    // setLoading(false);
  }
};
