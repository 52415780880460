// TextInput.js
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';
import SendIcon from '@material-ui/icons/Send';
import StopOutlined from '@material-ui/icons/StopOutlined';

import {
  TiltIcon,
  TextAreaWrapper,
  TextInputContaier,
  SendIconContainer,
  MsgIconContainer,
} from './styles';

const TextInput = ({
  textRef,
  loading,
  handleSend,
  onAbortClick,
}) => {
  const [textQuery, setTextQuery] = useState('');

  const onHandleOnChange = () => {
    const v = textRef.current.value;
    setTextQuery(v);
  };

  return (
    <TextAreaWrapper>
      <TextInputContaier
        type="text"
        ref={textRef}
        placeholder='Message Mercury'
        rows={3}
        cols={1}
        onChange={onHandleOnChange}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && !e.shiftKey && !loading) {
            e.preventDefault();
            handleSend(textQuery);
            setTextQuery('');
          }
        }}
      />
      <SendIconContainer>
        {!loading ? (
          <Tooltip placement="bottom-start" title={'Send'}>
            <MsgIconContainer isEnable={textQuery !== ''}>
              <TiltIcon>
                <SendIcon
                  onClick={() => {
                    handleSend(textQuery);
                    setTextQuery('');
                  }}
                  style={{
                    color: 'white',
                    fontSize: 20,
                  }}
                  alt="send-icon"
                  data-testid="send-button"
                />
              </TiltIcon>
            </MsgIconContainer>
          </Tooltip>
        ) : (
          <Tooltip placement="bottom-start" title={'Stop'}>
            <MsgIconContainer isEnable={true}>
              <StopOutlined
                onClick={onAbortClick}
                style={{
                  color: 'white',
                  fontSize: 24,
                }}
                alt="stop-icon"
              />
            </MsgIconContainer>
          </Tooltip>
        )}
      </SendIconContainer>
    </TextAreaWrapper>
  );
};

TextInput.propTypes = {
  textRef: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  handleSend: PropTypes.func.isRequired,
  onAbortClick: PropTypes.func.isRequired,
};

export default TextInput;
