import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Tooltip from '@mui/material/Tooltip';

import ThumbUpIcon from '@material-ui/icons/ThumbUpAltOutlined';
import ThumbUpIconPressed from '@material-ui/icons/ThumbUpAlt';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import ThumbDownIcon from '@material-ui/icons/ThumbDownOutlined';
import ThumbDownIconPressed from '@material-ui/icons/ThumbDownAlt';

import CONSTANTS from '../constants';

import {
  StyledIcon,
  AnimateIcons,
} from './styles';

const FeedbackSection = ({
  toAnimate,
  message,
  tooltipText,
  handleCopyClick,
  handleUpdateFeedback,
  onDisplayFeedbackList,
}) => {
  const [showStatisfiedIcon, toggleStatisfiedIcon] = useState(true);
  const [showUnstatisfiedIcon, toggleUnStatisfiedIcon] = useState(true);

  return (
    <>
      <AnimateIcons toAnimate={toAnimate}>
        <Tooltip placement="bottom-start" title={tooltipText}>
          <StyledIcon>
            <FileCopyIcon
              data-testid="btn-copy-response"
              onClick={() => handleCopyClick(message.text)}
              style={{
                color: '#1d5aa6',
                fontSize: 16,
              }}
              alt="good-response-icon"
            />
          </StyledIcon>
        </Tooltip>
        {showStatisfiedIcon && (
          <Tooltip placement="bottom-start" title={'Good response'}>
            {showUnstatisfiedIcon ? (
              <StyledIcon>
                <ThumbUpIcon
                  style={{
                    color: '#1d5aa6',
                    fontSize: 16,
                  }}
                  alt="good-response-icon"
                  onClick={() => {
                    handleUpdateFeedback({
                      message,
                      rating: CONSTANTS.FEEDBACK_RATINGS.THUMBS_UP,
                    });
                    toggleUnStatisfiedIcon(false);
                  }}
                />
              </StyledIcon>
            ) : (
              <ThumbUpIconPressed
                style={{
                  color: '#1d5aa6',
                  fontSize: 16,
                }}
                alt="good-response-icon"
              />
            )}
          </Tooltip>
        )}
        {showUnstatisfiedIcon && (
          <Tooltip placement="bottom-start" title={'Bad response'}>
            {showStatisfiedIcon ? (
              <StyledIcon>
                <ThumbDownIcon
                  style={{
                    color: '#1d5aa6',
                    fontSize: 16,
                  }}
                  alt="bad-response-icon"
                  onClick={() => {
                    handleUpdateFeedback({
                      message,
                      rating: CONSTANTS.FEEDBACK_RATINGS.THUMBS_DOWN,
                    });
                    toggleStatisfiedIcon(false);
                    onDisplayFeedbackList(true, message);
                  }}
                />
              </StyledIcon>
            ) : (
              <ThumbDownIconPressed
                style={{
                  color: '#1d5aa6',
                  fontSize: 16,
                }}
                alt="bad-response-icon"
              />
            )}
          </Tooltip>
        )}
      </AnimateIcons>
    </>
  );
};

FeedbackSection.propTypes = {
  toAnimate: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  tooltipText: PropTypes.string.isRequired,
  handleCopyClick: PropTypes.func.isRequired,
  onDisplayFeedbackList: PropTypes.func.isRequired,
  handleUpdateFeedback: PropTypes.func.isRequired,
};

export default FeedbackSection;
