import React, { Suspense } from 'react';
import PropTypes from 'prop-types';

import { useFlags } from 'launchdarkly-react-client-sdk';

import Page from '@pws-dev-portal/components/src/Page';
import Footer from '@pws-dev-portal/components/src/Footer';
import Progress from '@pws-dev-portal/components/src/Progress';
import AIAssistant from '@pws-dev-portal/components/src/AIAssistant';

// components
import Header from './Header';
import NavigationTabs from './NavigationTabs';
import NotificationBanner from './NotificationBanner';

const Main = (props) => {
  const featureFlags = useFlags();

  const {
    children,
    autoLogin = false,
  } = props;

  const fallback = () => (
    <Page>
      <Progress />
    </Page>
  );

  return (
    <>
      <NotificationBanner />
      <Header
        autoLogin={autoLogin}
      />
      <NavigationTabs />
      <Suspense fallback={fallback()}>
        {children}
      </Suspense>
      <Footer />
      {featureFlags?.displayPopupChat && (
        <AIAssistant apiBaseUrl={process.env.REACT_APP_AI_ASSISTANT_BASE_URL} />
      )}
    </>
  );
};

Main.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  autoLogin: PropTypes.bool,
};

export default Main;
